import './style.scss';
import React, { useEffect, useState } from 'react';
import ApiService from '@services/api.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { groupBy, map } from 'lodash';
import moment from 'moment';
import { Spin, Table } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import AnimatedNumbers from "react-animated-numbers";
import { ColumnsType } from 'antd/es/table';

am4core.useTheme(am4themes_animated);

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        width: 80,
        dataIndex: 'id',
    },
    {
        title: 'Name',
        width: 250,
        render: (row) => {
            return <div>
                <p style={{ margin: 0 }}>{`${row?.name}`}</p>
                <p style={{ margin: 0, fontSize: '13px', wordBreak: 'break-word' }}>{row?.profileEmail}</p>
            </div>
        }
    },
    {
        title: 'Active Learners',
        dataIndex: 'learners',
        width: 150,
    },
    {
        title: 'Active Leaders',
        dataIndex: 'leaders',
        width: 150,
    },
    {
        title: 'Subscription Amount',
        dataIndex: 'amount',
        render: (value) => {
            return value ? `$${value}` : 'Free Practice'
        }
    },
    {
        title: 'Subscription Date',
        dataIndex: 'currentPeriodStart',
        render: (value) => {
            return value ? moment(value).format('MMM Do YY') : '-'
        }
    },
];

function Dashboard() {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [activeLearnerCount, setActiveLearnerCount] = useState(0);
    const [activeMedicalPracticeCount, setActiveMedicalPracticeCount] = useState(0);
    const [closedPracticeCount, setClosedPracticeCount] = useState(0);
    const [activeLeadersCount, setActiveLeadersCount] = useState(0);
    const [searchResults, setSearchResults] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [paginationConfig, setPaginationConfig] = useState<any>();

    useEffect(() => {
        getActivePractices('');
    }, []);

    useEffect(() => {
        ApiService.getDashboard().then((response) => {
            if (response?.data?.data) {
                const activeLearners: any = response?.data?.data?.activeLearners;
                const activePractices: any = response?.data?.data?.activePractices;
                const closedPractices: any = response?.data?.data?.closedPractices;
                const activeLeaders: any = response?.data?.data?.activeLeaders;

                if (activeLearners) {
                    setActiveLearnerCount(activeLearners?.length);
                    const groupByDate = groupBy(activeLearners, ({ lastLoggedIn }) => new Date(lastLoggedIn).getMonth());
                    const finalResult = map(groupByDate, item => {
                        return {
                            count: item.length,
                            date: moment(item[0]?.lastLoggedIn).format('MMM Do YY')
                        }
                    });

                    loadActiveLearnerChart(finalResult);
                }

                if (activePractices) {
                    setActiveMedicalPracticeCount(activePractices?.length);
                    const groupByDate = groupBy(activePractices, ({ medicalPractice }) => new Date(medicalPractice?.createdAt).getMonth());
                    const finalResult = map(groupByDate, item => {
                        return {
                            count: item.length,
                            date: moment(item[0]?.medicalPractice?.createdAt).format('MMM Do YY')
                        }
                    });

                    loadActiveMedicalPracticeChart(finalResult);
                }

                if (closedPractices) {
                    setClosedPracticeCount(closedPractices?.length);
                    const groupByDate = groupBy(closedPractices, ({ medicalPractice }) => new Date(medicalPractice?.createdAt).getMonth());
                    const finalResult = map(groupByDate, item => {
                        return {
                            count: item.length,
                            date: moment(item[0]?.medicalPractice?.createdAt).format('MMM Do YY')
                        }
                    });

                    loadClosedPracticeChart(finalResult);
                }

                if (activeLeaders) {
                    setActiveLeadersCount(activeLeaders?.length);
                    const groupByDate = groupBy(activeLeaders, ({ lastLoggedIn }) => new Date(lastLoggedIn).getMonth());
                    const finalResult = map(groupByDate, item => {
                        return {
                            count: item.length,
                            date: moment(item[0]?.lastLoggedIn).format('MMM Do YY')
                        }
                    });

                    loadActiveLeadersChart(finalResult);
                }
            }
            setIsLoggedIn(false);
        }).catch(() => {
            setIsLoggedIn(false);
        });
    }, []);

    function getActivePractices(searchString: string) {
        setIsLoading(true);
        ApiService.getActivePractices().then((response) => {
            if (response?.data) {
                const customers = response?.data?.data?.practices;

                const processedData = map(customers, ((cus) => {
                    const leaderCount = cus?.medicalPractice?.learners?.filter((profile: any) => {
                        return profile.roleId === 1
                    });

                    const learnerCount = cus?.medicalPractice?.learners.filter((profile: any) => {
                        return profile.roleId === 2
                    });

                    return {
                        id: cus?.medicalPractice?.id,
                        name: cus?.medicalPractice?.practiceName,
                        email: cus?.medicalPractice?.email,
                        currentPeriodStart: cus?.currentPeriodStart,
                        currentPeriodEnd: cus?.currentPeriodEnd,
                        amount: cus?.amount,
                        leaders: leaderCount?.length,
                        learners: learnerCount?.length,
                    }
                }));

                setSearchResults(processedData);
            }
            setIsLoading(false);
        }).catch((error) => {
            console.log('error: ', error);
        });
    }

    function loadActiveLearnerChart(chartData: any) {
        const chart = am4core.create('chart1', am4charts.XYChart);
        chart.data = chartData;
        chart.padding(0, 0, 0, 0);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "date";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.hidden = true;
        categoryAxis.renderer.disabled = true;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.min = 0;
        valueAxis.hidden = true;
        valueAxis.renderer.disabled = true;

        const gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color('#495473'));
        gradient.addColor(am4core.color('#faa63d'));

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "date";
        lineSeries.dataFields.valueY = "count";
        lineSeries.tooltipText = "{date}: {count} (Active)";
        lineSeries.fillOpacity = 1;
        lineSeries.strokeWidth = 1;
        lineSeries.fill = gradient;
        lineSeries.stroke = gradient;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = -1
    }

    function loadActiveMedicalPracticeChart(chartData: any) {
        const chart = am4core.create('chart2', am4charts.XYChart);
        chart.data = chartData;
        chart.padding(0, 0, 0, 0);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "date";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.hidden = true;
        categoryAxis.renderer.disabled = true;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.min = 0;
        valueAxis.hidden = true;
        valueAxis.renderer.disabled = true;

        const gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color('#60d1de'));
        gradient.addColor(am4core.color('#3cc296'));

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "date";
        lineSeries.dataFields.valueY = "count";
        lineSeries.tooltipText = "{date}: {count} (Registered)";
        lineSeries.fillOpacity = 1;
        lineSeries.strokeWidth = 1;
        lineSeries.fill = gradient;
        lineSeries.stroke = gradient;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = -1
    }

    function loadClosedPracticeChart(chartData: any) {
        const chart = am4core.create('chart3', am4charts.XYChart);
        chart.data = chartData;
        chart.padding(0, 0, 0, 0);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "date";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.hidden = true;
        categoryAxis.renderer.disabled = true;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.min = 0;
        valueAxis.hidden = true;
        valueAxis.renderer.disabled = true;

        const gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color('#fdd6cb'));
        gradient.addColor(am4core.color('#f68e89'));

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "date";
        lineSeries.dataFields.valueY = "count";
        lineSeries.tooltipText = "{date}: {count} (Canceled)";
        lineSeries.fillOpacity = 1;
        lineSeries.strokeWidth = 1;
        lineSeries.fill = gradient;
        lineSeries.stroke = gradient;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = -1
    }

    function loadActiveLeadersChart(chartData: any) {
        const chart = am4core.create('chart4', am4charts.XYChart);
        chart.data = chartData;
        chart.padding(0, 0, 0, 0);

        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.ticks.template.disabled = true;
        categoryAxis.renderer.line.opacity = 0;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.minGridDistance = 40;
        categoryAxis.dataFields.category = "date";
        categoryAxis.startLocation = 0.4;
        categoryAxis.endLocation = 0.6;
        categoryAxis.hidden = true;
        categoryAxis.renderer.disabled = true;

        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.line.opacity = 0;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.min = 0;
        valueAxis.hidden = true;
        valueAxis.renderer.disabled = true;

        const gradient = new am4core.LinearGradient();
        gradient.addColor(am4core.color('#7469B6'));
        gradient.addColor(am4core.color('#E1AFD1'));

        const lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "date";
        lineSeries.dataFields.valueY = "count";
        lineSeries.tooltipText = "{date}: {count} (Active)";
        lineSeries.fillOpacity = 1;
        lineSeries.strokeWidth = 1;
        lineSeries.fill = gradient;
        lineSeries.stroke = gradient;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.maxTooltipDistance = -1
    }

    const selectedOptionChange = (selectedOption: any) => {
        setPaginationConfig(selectedOption);
    };

    return (
        <div>
            <Spin spinning={isLoggedIn}>
                <div style={{ display: "flex", marginBottom: 10 }}>
                    <div className="stats-card">
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className='number-count'>
                                <AnimatedNumbers includeComma
                                    transitions={(index) => ({
                                        type: "spring",
                                        duration: index + 0.3,
                                    })}
                                    animateToNumber={activeLearnerCount}
                                    fontStyle={{ fontSize: 30, fontWeight: 'bold' }}
                                />
                                <ArrowUpOutlined style={{ marginLeft: 4, marginBottom: 4, color: '#139213', fontSize: 20 }} rev={undefined} />
                            </div>

                            <p style={{ margin: 0 }}>Active Learners</p>
                        </div>

                        <div className="chart1 line-chart"></div>
                    </div>

                    <div className="stats-card">
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className='number-count'>
                                <AnimatedNumbers includeComma
                                    transitions={(index) => ({
                                        type: "spring",
                                        duration: index + 0.3,
                                    })}
                                    animateToNumber={activeMedicalPracticeCount}
                                    fontStyle={{ fontSize: 30, fontWeight: 'bold' }}
                                />
                                <ArrowUpOutlined style={{ marginLeft: 4, marginBottom: 4, color: '#139213', fontSize: 20 }} rev={undefined} />
                            </div>

                            <p style={{ margin: 0 }}>Active Customers</p>
                        </div>
                        <div className="chart2 line-chart"></div>
                    </div>

                    <div className="stats-card">
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className='number-count'>
                                <AnimatedNumbers includeComma
                                    transitions={(index) => ({
                                        type: "spring",
                                        duration: index + 0.3,
                                    })}
                                    animateToNumber={closedPracticeCount}
                                    fontStyle={{ fontSize: 30, fontWeight: 'bold' }}
                                />
                                <ArrowDownOutlined style={{ marginLeft: 4, marginBottom: 4, color: '#bf0d0d', fontSize: 20 }} rev={undefined} />
                            </div>

                            <p style={{ margin: 0 }}>Closed Customers</p>
                        </div>
                        <div className="chart3 line-chart"></div>
                    </div>

                    <div className="stats-card">
                        <div style={{ padding: 20, paddingBottom: 0 }}>
                            <div className='number-count'>
                                <AnimatedNumbers includeComma
                                    transitions={(index) => ({
                                        type: "spring",
                                        duration: index + 0.3,
                                    })}
                                    animateToNumber={activeLeadersCount}
                                    fontStyle={{ fontSize: 30, fontWeight: 'bold' }}
                                />
                            </div>

                            <p style={{ margin: 0 }}>Active Leaders</p>
                        </div>
                        <div className="chart4 line-chart"></div>
                    </div>
                </div>
            </Spin>

             <div style={{ marginTop: 40 }}>
                 <h2>Active Customers</h2>

                  <div className="table-container">
                    <Table bordered loading={isLoading} columns={columns} dataSource={searchResults} pagination={{ pageSize: paginationConfig?.pageSize || 50 }} scroll={{ y: 'calc(100vh - 400px)', x: 100 }} onChange={selectedOption => selectedOptionChange(selectedOption)} />
                  </div>                  
            </div>                       
        </div>
    );
}

export default Dashboard;
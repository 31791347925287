import './style.scss';
import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { SearchProps } from 'antd/es/input';
import { DownloadOutlined, RightOutlined } from '@ant-design/icons';

import moment from 'moment';
import exportFromJSON from 'export-from-json';
import ApiService from '@services/api.service';

const { Search } = Input;

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        width: 80,
        dataIndex: 'profileId',
    },
    {
        title: 'Name',
        width: 180,
        render: (row) => {
            return <div>
                <p style={{ margin: 0 }}>{`${row?.profileName}`}</p>
                <p style={{ margin: 0, fontSize: '13px', wordBreak: 'break-word' }}>{row?.profileEmail}</p>
            </div>
        }
    },
    {
        title: 'Practice Name',
        dataIndex: 'medicalPracticeName'
    },
    {
        title: 'Learnupon Group (ID)',
        width: 200,
        render: (row) => {
            let groupText = '';
            switch (row?.luGroupId) {
                case '708371':
                    groupText = 'Legacy';
                    break;

                case '709610':
                    groupText = 'Premium';
                    break;

                case '709608':
                    groupText = 'Essential';
                    break;

                case '709609':
                    groupText = 'Essential';
                    break;

                default:
                    break;
            }

            return `${groupText} ${row?.luGroupId === 'null' ? '' : `(${row?.luGroupId})`}`
        }
    },
    {
        title: 'License Tier',
        render: (row) => {
            return `${row?.planName}`
        }
    },
    {
        title: 'Learnupon ID',
        dataIndex: 'luId',
        render: (value) => {
            return value === 'null' ? '-' : value
        } 
    },
    {
        title: 'Last LoggedIn',
        dataIndex: 'lastLoggedIn',
        render: (value) => {
            if (value && value !== 'null') {
                return moment(value).startOf('hour').fromNow();
            }

            return '-'
        },
    },
    {
        title: 'Role',
        render: (row) => {
            if (row?.profileRoleId === '1') {
                return 'Leader';
            } else if (row?.profileRoleId === '2') {
                return 'Learner';
            } else {
                return '-';
            }
        },
    },
    {
        title: 'Status',
        render: (row) => {
            const status = row?.status?.charAt(0)?.toUpperCase() + row?.status?.slice(1)?.toLowerCase();

            if (status === 'Expired') {
                return 'Inactive'
            }

            return status;
        },
    },
    {
      title: ' ',
      width: 80,
      render: () => {
        return <RightOutlined rev={undefined} />
      },
    },
];

function AzureSearch() {
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [paginationConfig, setPaginationConfig] = useState<any>();

    useEffect(() => {
        getSearchResults('');
    }, []);

    function getSearchResults(searchString: string) {
        setIsLoading(true);
        ApiService.search({ searchText: searchString }).then((response) => {
            if (response?.data) {
                setSearchResults(response?.data?.data);
            }
            setIsLoading(false);
        }).catch((error) => {
            console.log('error: ', error);
        });
    }

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => {
        getSearchResults(value);
    }

    const onExport = () => {
        setIsExportLoading(true);

        setTimeout(() => {
            const date = new Date();
            const fileName = `export-${date.getTime()}`;
            const exportType =  exportFromJSON.types.csv;

            exportFromJSON({ data: searchResults, fileName, exportType });

            setIsExportLoading(false);
        }, 1000);
    }

    const selectedOptionChange = (selectedOption: any) => {
        setPaginationConfig(selectedOption);
    };

    return (
        <div>
            <div className="section-header" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h2>Search</h2>
                
                <div>
                    <Search
                        size="large"
                        style={{ width: "400px", marginRight: 10 }}
                        placeholder="Search..."
                        enterButton="Search"
                        onSearch={onSearch}
                    />

                    <Button loading={isExportLoading} onClick={() => { onExport() }} type="primary" size='large' icon={<DownloadOutlined rev={undefined} />}>Export</Button>
                </div>
               
            </div>
            <div className="table-container">
                <Table bordered loading={isLoading} columns={columns} dataSource={searchResults} pagination={{ pageSize: paginationConfig?.pageSize || 50 }} scroll={{ y: 'calc(100vh - 400px)', x: 100 }} onChange={selectedOption => selectedOptionChange(selectedOption)} />
            </div>
        </div>
    );
}

export default AzureSearch;

import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import apiService from '@services/api.service';
import { toast } from 'react-toastify';

type FieldType = {
    email?: string;
    password?: string;
};

function ChangePassword() {

    const [loading, setLoading] = useState(false);

    const onFinish = (values: any) => {
        setLoading(true);
        apiService.changePassword(values).then(() => {
            setLoading(false);
            toast.success("Password have been modified!", {
                position: "top-right",
            });
        }).catch((error) => {
            setLoading(false);
            toast.error(error?.response?.data?.message, {
                position: "top-right",
            });
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
            <Form
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600, width: 400 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                size='large'
            >
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input email!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button loading={loading} size="large" type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
    )
}

export default ChangePassword;
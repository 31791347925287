import './style.scss';
import React, { useEffect, useState } from 'react';
import { Table, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownloadOutlined } from '@ant-design/icons';
import { get } from 'lodash';

import ApiService from '@services/api.service';
import exportFromJSON from 'export-from-json';

interface DataType {
    key: React.Key;
    name: string;
    age: number;
    address: string;
}

interface StatValueDisplayProps {
    value: number;
    animate?: boolean;
}

interface PracticeData {
    medicalPracticeId: string;
    practiceName: string;
    stats: {
        totalAllocatedSeats: number;
        essential: number;
        professional: number;
        premium: number;
        legacy: number;
        leader: number;
    };
}

const StatValueDisplay = ({ value }: StatValueDisplayProps) => {
   return <span className={value === 0 ? 'red' : ''}>{value}</span>
}

const renderStatValue = (value: any, key: string) => {
    const statValue = get(value, `stats.${key}`, 0);
    return <StatValueDisplay value={statValue} />;
};

const columns: ColumnsType<DataType> = [
    {
        title: 'ID',
        width: 80,
        dataIndex: 'medicalPracticeId',
    },
    {
        title: 'SID',
        width: 80,
        dataIndex: 'subscriptionId',
    },
    {
        title: 'Name',
        width: 240,
        render: (row) => (
            <div>
                <p style={{ margin: 0 }}>{row?.practiceName}</p>
            </div>
        ),
    },
    {
        title: '# Active Licenses',
        render: (value) => renderStatValue(value, 'totalAllocatedSeats'),
    },
    {
        title: 'Essential',
        render: (value) => renderStatValue(value, 'essential'),
    },
    {
        title: 'Professional',
        render: (value) => renderStatValue(value, 'professional'),
    },
    {
        title: 'Premium',
        render: (value) => renderStatValue(value, 'premium'),
    },
    {
        title: 'Legacy',
        render: (value) => renderStatValue(value, 'legacy'),
    },
    {
        title: 'Leaders',
        render: (value) => renderStatValue(value, 'leader'),
    },
];

function MedicalPractices() {
    const [searchResults, setSearchResults] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paginationConfig, setPaginationConfig] = useState<any>();
    const [isExportLoading, setIsExportLoading] = useState(false);

    useEffect(() => {
        getActivePractices();
    }, []);

    function getActivePractices() {
        setIsLoading(true);

        ApiService.getActivePracticesInfo().then((response) => {
            if (response?.data) {
                const result = response?.data?.result?.data;
                setSearchResults(result);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            console.log('error: ', error);
        });
    }

    const selectedOptionChange = (selectedOption: any) => {
        setPaginationConfig(selectedOption);
    };

    const formatKey = (key: string): string => {
        switch (key) {
            case 'id':
                return 'ID';
            case 'totalAllocatedSeats':
                return '# Active Licenses';
            default:
                return key.replace(/([A-Z])/g, ' $1') // Add space before capital letters
                          .replace(/^./, (str: string) => str.toUpperCase()); // Capitalize the first letter
        }
    };

    const mapExportData = (data: PracticeData[]) => {
        return data.map((item) => ({
            id: item.medicalPracticeId,
            name: item.practiceName,
            totalAllocatedSeats: item.stats.totalAllocatedSeats,
            essential: item.stats.essential,
            professional: item.stats.professional,
            premium: item.stats.premium,
            legacy: item.stats.legacy,
            leader: item.stats.leader,
        }));
    };

    const onExport = async () => {
        const exportData = mapExportData(searchResults);
    
        setIsExportLoading(true);
    
        try {
            await exportFromJSON({
                data: exportData,
                fileName: 'export-active-practices',
                exportType: exportFromJSON.types.csv,
                beforeTableEncode: (rows) => {
                    return rows.map((row) => ({
                        ...row,
                        fieldName: formatKey(row.fieldName),
                    }));
                },
            });
        } catch (error) {
            console.error('Error exporting data:', error);
        } finally {
            setIsExportLoading(false);
        }
    };

    return (
        <div>
            <div className="header-actions">
                <h2>Active Practices (License Details)</h2>
                <div>
                    <Button
                        loading={isExportLoading}
                        onClick={onExport}
                        type="primary"
                        size="large"
                        icon={<DownloadOutlined rev={undefined} />}
                    >
                        Export
                    </Button>
                </div>
            </div>

            <div className="table-container">
                <Table
                    bordered
                    loading={isLoading}
                    columns={columns}
                    dataSource={searchResults}
                    pagination={{ pageSize: paginationConfig?.pageSize || 50 }}
                    scroll={{ y: 'calc(100vh - 400px)', x: 100 }}
                    onChange={selectedOptionChange}
                />
            </div>
        </div>
    );
}

export default MedicalPractices;